<template>
   <div v-if="pRecord"
      style="background-color:#444444;border-radius: 1.5vmin;padding: 0.5vmin 0.5vmin;">
      <div class="plateText pl-2 pr-1"
         :class="{
            'plateColorIn': pRecord.direction=='in',
            'plateColorOut': pRecord.direction=='out',
            }">
         {{pRecord.plate}}
      </div>
      <div class="infoRow pl-0 pr-1">
         <div style="display:inline-block;float:left;">
            <template v-if="pRecord.direction=='out'">
               <v-icon 
                  class="iconOut">
                  mdi-arrow-up-bold
               </v-icon>
               <span class="labelTextDir ml-1 mr-3"><b>RA</b></span>
               <span class="datetimeText">
                  <i>{{$moment(pRecord.at).format("HH:mm DD/MM/YY")}}</i>
               </span>
            </template>
            <template v-if="pRecord.direction=='in'">
               <v-icon 
                  class="iconIn">
                  mdi-arrow-down-bold
               </v-icon>
               <span class="labelTextDir ml-1 mr-3"><b>VÀO</b></span>
               <span class="datetimeText">
                  <i>{{$moment(pRecord.at).format("HH:mm DD/MM/YY")}}</i>
               </span>
            </template>
            
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: ['pRecord'],
   data () {
      return {
      }
   }
}
</script>

<style lang="scss">
   .plateColorIn {
      color: #EED80E;
   }

   .plateColorOut {
      color: #2FF743;
   }

   .plateText {
      font-size: 4.2vmin;
      line-height:1.35;
      font-weight:700;
      text-align:left;
      letter-spacing: 0.15vmin;
   }
   .infoRow {
      height: 5vmin
   }
   .datetimeText {
      font-size: 2.2vmin;
      line-height:1.2;
      font-weight:300;
      text-align:right;
      color: white;
   }

   .labelTextDir {
      font-size: 2.4vmin;
      line-height:1;
      font-weight:500;
      color: white;
   }

   .iconIn {
      font-size: 4.2vmin !important;
      color: #F37D4A !important;
      margin-top: -4px;
   }
   .iconOut {
      font-size: 4.2vmin !important;
      color: #54F7ED !important;
      margin-top: -4px;
   }
   
</style>